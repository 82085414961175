export default [
  {
    navId: 'dealer-installer',
    title: 'Installer',
    route: 'dealer-installer',
    icon: 'UsersIcon',
    role: 'Dealer'
  },
  {
    navId: 'dealer-orders',
    title: 'Manage Order',
    route: 'dealer-orders',
    icon: 'UsersIcon',
    role: 'Dealer',
    children: [
      {
        navId: 'dealer-sales1',
        title: 'All Order',
        route: 'dealer-orders-all',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-sales2',
        title: 'Order End Customer',
        route: 'dealer-order-end-customer',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-sales3',
        title: 'Order Warehouse',
        route: 'dealer-order-warehouse',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-sales4',
        title: 'Order Collect End Customer ',
        route: 'dealer-order-self-end-customer',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-sales5',
        title: 'Order Collect Warehouse',
        route: 'dealer-order-self-warehouse',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-sales6',
        title: 'Order Install End Customer',
        route: 'dealer-workorder-pending-end-customer',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
    ]
  },
  // {
  //   navId: 'dealer-receiving',
  //   title: 'Receiving',
  //   route: 'dealer-receiving',
  //   icon: 'UsersIcon',
  //   role: 'Dealer'
  // },
  // {
  //   navId: 'dealer-delivery',
  //   title: 'Delivery Order',
  //   route: 'dealer-delivery',
  //   icon: 'UsersIcon',
  //   role: 'Dealer'
  // },
  {
    navId: 'dealer-delivery',
    title: 'Work Order',
    route: 'dealer-workorders',
    icon: 'UsersIcon',
    role: 'Dealer'
  },
  {
    navId: 'dealer-warranty-registration',
    title: 'Warranty Registration ',
    route: 'dealer-warranty-pending',
    icon: 'UsersIcon',
    role: 'Dealer'
  },
  {
    navId: 'dealer-inventories',
    title: 'Manage Inventory',
    route: 'item',
    icon: 'UsersIcon',
    role: 'Dealer',
    children: [
      {
        navId: 'dealer-inventories-warehouse',
        title: 'Inventory Warehouse',
        route: 'dealer-inventories-warehouse',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
      {
        navId: 'dealer-inventories-end-customer',
        title: 'Inventory End Customer',
        route: 'dealer-inventories-end-customer',
        icon: 'UsersIcon',
        role: 'Dealer'
      },
    ]
  },
]
