export default [
  {
    navId: 'sumitomo-delivery',
    title: 'Delivery',
    route: 'item',
    icon: 'UsersIcon',
    role: 'Sumitomo',
    children: [
      {
        navId: 'sumitomo-self-collect',
        title: 'Self Collect',
        route: 'sumitomo-confirm-collect',
        icon: 'UsersIcon',
        role: 'Sumitomo',
      },
      {
        navId: 'sumitomo-delivery',
        title: 'Delivery',
        route: 'sumitomo-confirm-delivery',
        icon: 'UsersIcon',
        role: 'Sumitomo',
      },
    ],
  }
]
