export default [
  {
    navId: 'admin-item-master',
    title: 'Master Data',
    route: 'item',
    icon: 'UsersIcon',
    role: 'Admin',
    children: [
      {
        navId: 'admin-item',
        title: 'Item Master',
        route: 'admin-items',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-item-bundles',
        title: 'Set Master',
        route: 'admin-item-bundles',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-item-categories',
        title: 'Category Master',
        route: 'admin-item-categories',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-dealer',
        title: 'Dealer Master',
        route: 'admin-dealer',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-installer',
        title: 'Installer Master',
        route: 'admin-installer',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-pcode',
        title: 'PCode Master',
        route: 'admin-manage-pcode',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-postal',
        title: 'Post Master',
        route: 'admin-postal',
        icon: 'UsersIcon',
        role: 'Admin',
      },
    ],
  },
  {
    navId: 'admin-sales',
    title: 'Sales Order',
    route: 'admin-sales',
    icon: 'UsersIcon',
    role: 'Admin',
  },
  {
    navId: 'admin-holidays',
    title: 'Holiday Management',
    route: 'admin-manage-holiday',
    icon: 'UsersIcon',
    role: 'Admin',
  },
  {
    navId: 'admin-inventory',
    title: 'Inventory',
    icon: 'UsersIcon',
    role: 'Admin',
    children: [
      {
        navId: 'admin-inventory-all',
        title: 'All Non Drcc Item',
        route: 'admin-inventory-all',
        icon: 'UsersIcon',
        role: 'Admin',
      }
    ]
  },
  // {
  //   navId: 'admin-deliveries',
  //   title: 'Delivery',
  //   icon: 'UsersIcon',
  //   role: 'Admin',
  //   children: [
  //     {
  //       navId: 'admin-deliveries-self',
  //       title: 'Self Collect Delivery',
  //       icon: 'UsersIcon',
  //       route: 'admin-deliveries-self',
  //       role: 'Admin',
  //     },
  //     {
  //       navId: 'admin-deliveries-delivery',
  //       title: 'Delivery',
  //       icon: 'UsersIcon',
  //       route: 'admin-deliveries',
  //       role: 'Admin',
  //     },
  //   ],
  // },
  {
    navId: 'admin-warranties',
    title: 'Warranty Register',
    icon: 'UsersIcon',
    role: 'Admin',
    children: [
      {
        navId: 'admin-warranty-internal',
        title: 'Internal Warranty Registration',
        route: 'admin-warranty-internal',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-warranty-external',
        title: 'External Warranty Registration',
        route: 'admin-warranty-external',
        icon: 'UsersIcon',
        role: 'Admin',
      },
    ]
  },
  {
    navId: 'admin-manage-users',
    title: 'Manage User',
    icon: 'UsersIcon',
    role: 'Admin',
    children: [
      {
        navId: 'admin-users-admin',
        title: 'User Admin',
        route: 'admin-users-admin',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-users-dealer',
        title: 'User Dealer',
        route: 'admin-users-dealer',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-users-installer',
        title: 'User Installer',
        route: 'admin-users-installer',
        icon: 'UsersIcon',
        role: 'Admin',
      },
      {
        navId: 'admin-users-sumitomo',
        title: 'User Sumitomo',
        route: 'admin-users-sumitomo',
        icon: 'UsersIcon',
        role: 'Admin',
      }
    ]
  },
]
