export default [
  // {
  //   navId: 'installer-delivery',
  //   title: 'Delivery Order',
  //   route: 'installer-delivery',
  //   icon: 'UsersIcon',
  //   role: 'Installer'
  // },
  {
    navId: 'installer-workorders',
    title: 'Manage Installation',
    route: 'installer-workorders',
    icon: 'UsersIcon',
    role: 'Installer'
  },
  {
    navId: 'installer-register-warranty',
    title: 'Warranty Registration',
    route: 'installer-register-warranty',
    icon: 'UsersIcon',
    role: 'Installer'
  },
]
