import adminNav from './admin-navigation';
import dealerNav from './dealer-navigation';
import installerNav from './installer-navigation';
import sumitomoNav from './sumitomo-navigation';

export default [
  ...adminNav,
  ...dealerNav,
  ...installerNav,
  ...sumitomoNav,
]
